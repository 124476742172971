import { AvField, AvForm } from "availity-reactstrap-validation";
import moment from "moment";
import React from "react";
import { Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { addWeight } from "../../service/weightService";

class AddWeight extends React.Component {
  state = {
    date: moment().format("YYYY-MM-DD"),
    weight: "",
    modal: false,
    showInfo: false,
    showError: true,
    errMsg: "",
    infoMsg: "",
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  openModal = () => {
    this.setState({
      modal: !this.state.modal,
      date: moment().format("YYYY-MM-DD"),
      weight: "",
    });
  };

  handleWeightInput = (e) => {
    this.setState({
      weight: e.target.value,
    });
  };

  handleDateInput = (e) => {
    this.setState({
      date: e.target.value,
    });
  };

  handleValidSubmit = () => {
    addWeight(this.state.date, this.state.weight).then((response) => {
      this.props.updateWeights();
    }).catch((error) => console.error(error));
    this.toggle();
  }


  render() {
    return (
      <>
        <Button className="btn-round btn-icon" onClick={this.openModal}>
          <i className="tim-icons icon-simple-add" />
        </Button>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader>
            <h5 className="title">Add bodyweight</h5>
          </ModalHeader>
          <AvForm onValidSubmit={this.handleValidSubmit}>
            <ModalBody>
              <FormGroup>
                <Label for="Date">Date</Label>
                <AvField
                  type="date"
                  name="date"
                  id="date"
                  className="text-black-50"
                  defaultValue={moment().format("YYYY-MM-DD")}
                  onChange={this.handleDateInput}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="weight">Weight</Label>
                <AvField
                  type="number"
                  name="weight"
                  id="weight"
                  className="text-black-50"
                  required
                  onChange={this.handleWeightInput}
                  min={0}
                  max={999}
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button
                className="tim-white-buttons"
                type="button"
                onClick={this.toggle}
              >
                Close
              </Button>
              <Button className="tim-white-buttons" type="submit">
                Add
              </Button>
            </ModalFooter>
          </AvForm>
        </Modal>
      </>
    );
  }
}

export default AddWeight;
