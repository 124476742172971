import { AvField, AvForm } from "availity-reactstrap-validation";
import React from "react";
import { Button, Card, CardBody, CardFooter, CardHeader, Col, FormGroup, Label, Row } from "reactstrap";
import { getEstimatedMax } from "../../service/oneRepMaxService";


class OneRepMaxCalc extends React.Component {
  state = {
    hasEstimatedOneRepMax: false,
    estimatedOneRepMax: "",
    weight: "",
    reps: "",
    rpe: "",
    error: false,
    errMsg: ""
  };

  handleWeightInput = e => {
    this.setState({
      weight: e.target.value
    });
  };

  handleRpeInput = e => {
    this.setState({
      rpe: e.target.value
    });
  };

  handleRepsInput = e => {
    this.setState({
      reps: e.target.value
    });
  };

  handleValidSubmit = () => {
    let errMsg;
    getEstimatedMax(this.state.weight, this.state.reps, this.state.rpe)
      .then(result => {
        this.setState({
          error: false,
          errMsg: "",
          hasEstimatedOneRepMax: true,
          estimatedOneRepMax: result.data.result
        });
      })
      .catch(error => {
        if (error && error.status && error.status === 400) {
          errMsg = error.data.message;
        } else {
          errMsg = "Something went wrong, please try again later";
        }
        this.setState({
          error: true,
          hasEstimatedOneRepMax: false,
          estimatedOneRepMax: "",
          errMsg
        });
      });
  };

  render() {
    const { errMsg } = this.state;
    return (
      <>
        <div className="content">
          <div className="col-lg-4 col-md-4 col-sm-4 container justify-content-center">
            <Card>
              <CardHeader>
                <h5 className="title">Calculate 1RM</h5>
              </CardHeader>
              <AvForm onValidSubmit={this.handleValidSubmit}>
                <CardBody>
                  <Row>
                    <Col className="pr-md-1" md="">
                      <FormGroup>
                        <Label for="weight">Weight</Label>
                        <AvField
                          type="number"
                          name="weight"
                          id="weight"
                          required
                          min={0}
                          max={999}
                          onChange={this.handleWeightInput}
                        />
                      </FormGroup>
                    </Col>
                    <Col className="px-md-1" md="3">
                      <FormGroup>
                        <Label for="reps">Reps</Label>
                        <AvField
                          type="select"
                          name="reps"
                          id="reps"
                          required
                          onChange={this.handleRepsInput}
                        >
                          <option className="text-black-50" />
                          <option className="text-black-50">1</option>
                          <option className="text-black-50">2</option>
                          <option className="text-black-50">3</option>
                          <option className="text-black-50">4</option>
                          <option className="text-black-50">5</option>
                          <option className="text-black-50">6</option>
                          <option className="text-black-50">7</option>
                          <option className="text-black-50">8</option>
                          <option className="text-black-50">9</option>
                          <option className="text-black-50">10</option>
                        </AvField>
                      </FormGroup>
                    </Col>
                    <Col className="pl-md-1" md="3">
                      <FormGroup>
                        <Label for="rpe">RPE</Label>
                        <AvField
                          type="select"
                          name="rpe"
                          id="rpe"
                          required
                          onChange={this.handleRpeInput}
                        >
                          <option className="text-black-50" />
                          <option className="text-black-50">5</option>
                          <option className="text-black-50">5.5</option>
                          <option className="text-black-50">6</option>
                          <option className="text-black-50">6.5</option>
                          <option className="text-black-50">7</option>
                          <option className="text-black-50">7.5</option>
                          <option className="text-black-50">8</option>
                          <option className="text-black-50">8.5</option>
                          <option className="text-black-50">9</option>
                          <option className="text-black-50">9.5</option>
                          <option className="text-black-50">10</option>
                        </AvField>
                      </FormGroup>
                    </Col>
                  </Row>
                  {this.state.error && (
                    <span className="text-danger"> {errMsg}</span>
                  )}
                </CardBody>
                <CardFooter>
                  <Button className="tim-white-buttons btn-block" type="submit">
                    Calculate
                  </Button>
                </CardFooter>
              </AvForm>
              <CardBody>
                {this.state.estimatedOneRepMax && (
                  <h4 className="title">
                    Your estimated one rep max is{" "}
                    {this.state.estimatedOneRepMax}
                  </h4>
                )}
              </CardBody>
            </Card>
          </div>
        </div>
      </>
    );
  }
}

export default OneRepMaxCalc;
