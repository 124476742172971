import React from "react";
import { Button, Popover, PopoverBody } from "reactstrap";

class MainEntryRow extends React.Component {
  state = {
    popoverOpen: false,
  };

  toggle = () => {
    this.setState({ popoverOpen: !this.state.popoverOpen });
  };

  render() {
    const lift =
      this.props.entry.lift +
      (this.props.entry.tempo !== '' ||
      this.props.entry.equipment !== ''
        ? " ("
        : "") +
      (this.props.entry.tempo !== '' ? this.props.entry.tempo : "") +
      (this.props.entry.tempo !== '' &&
      this.props.entry.equipment !== ''
        ? ", "
        : "") +
      (this.props.entry.equipment !== ''
        ? this.props.entry.equipment
        : "") +
      (this.props.entry.tempo !== '' ||
      this.props.entry.equipment !== ''
        ? ")"
        : "");
    return (
      <>
        <tr onClick={this.toggle} id={"row" + this.props.entry._id} className={this.props.entry.comment !== '' ? 'hover-div' : ''}>
          <td>{lift} {this.props.entry.comment !== '' ? <i className='tim-icons icon-paper'/> : ''}</td>
          <td>{this.props.entry.weight}</td>
          <td>{this.props.entry.reps}</td>
          <td>{this.props.entry.rpe}</td>
          <td className="font-weight-bold">
            {this.props.entry.estimatedMax !== 0
              ? this.props.entry.estimatedMax
              : ""}
          </td>
          <td className="text-left">
            <Button
              className="btn-icon btn-simple"
              color="default"
              size="sm"
              value={this.props.entry._id}
              onClick={(e) => this.props.deleteEntry(e.target.value)}
            >
              <i className="fa fa-times" />X
            </Button>
          </td>
        </tr>
        <tr>
          {this.props.entry.comment && (
            <Popover
              placement="bottom"
              isOpen={this.state.popoverOpen}
              target={"row" + this.props.entry._id}
            >
              <PopoverBody>{this.props.entry.comment}</PopoverBody>
            </Popover>
          )}
        </tr>
      </>
    );
  }
}

export default MainEntryRow;
