import React from "react";
import {AvForm, AvField} from 'availity-reactstrap-validation';

import {
    FormGroup,
    Label,
    Button,
    Card,
    CardBody,
    CardHeader,
    CardFooter
} from "reactstrap";
import {signup} from "../../service/authService";


class SignUp extends React.Component {
    state = {
        name: '',
        email: '',
        password: '',
        betaKey: '',
        signupFailed: false,
        errMessage: ''
    };

    handleNameInput = (e) => {
        this.setState({
            name: e.target.value
        })
    };

    handleEmailInput = (e) => {
        this.setState({
            email: e.target.value
        })
    };

    handlePasswordInput = (e) => {
        this.setState({
            password: e.target.value
        })
    };

    handleBetaKeyInput = (e) => {
        this.setState({
            betaKey: e.target.value
        })
    };

    handleValidSubmit = () => {
        signup(this.state.name, this.state.email, this.state.password, this.state.betaKey)
            .then(() => {
                this.props.history.push({
                    pathname: '/admin/login',
                    state: { signUpSucceed: true }
                });
            })
            .catch(error => {
                let err;
                if (error && error.data && error.data.errors && error.data.errors.password) {
                    err = "Invalid password, must be atleast 7 letters."
                } else if (error && error.data && error.data.errors && error.data.errors.email) {
                    err = "Invalid email format."
                } else if (error && error.data && error.data.errors && error.data.errors.name) {
                    err = "Invalid name."
                } else if (error && error.data && error.data && error.data.message) {
                    err = error.data.message
                } else {
                    err = "Something went wrong. Please try again."
                }
                this.setState({
                    signupFailed: true,
                    errMessage: err
                })
            })
    };

    render() {
        return (
            <div className="content">
                <div className="col-lg-4 col-md-4 col-sm-4 container justify-content-center">
                    <Card>
                        <CardHeader>
                            <h4 className="title">Sign up</h4>
                        </CardHeader>
                        <AvForm onValidSubmit={this.handleValidSubmit}>
                            <CardBody>
                                <FormGroup>
                                    <Label for="name">Name</Label>
                                    <AvField
                                        type="text"
                                        name="name"
                                        id="name"
                                        placeholder="Enter name"
                                        onChange={this.handleNameInput}
                                        required
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="email">Email address</Label>
                                    <AvField
                                        type="email"
                                        name="email"
                                        id="email"
                                        placeholder="Enter email"
                                        onChange={this.handleEmailInput}
                                        required
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="password">Password</Label>
                                    <AvField
                                        type="password"
                                        name="password"
                                        id="password"
                                        placeholder="Password"
                                        autoComplete="off"
                                        onChange={this.handlePasswordInput}
                                        required
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="betaKey">Beta key</Label>
                                    <AvField
                                        type="text"
                                        name="betaKey"
                                        id="betaKey"
                                        placeholder="Enter beta key"
                                        onChange={this.handleBetaKeyInput}
                                        required
                                    />
                                </FormGroup>
                                {
                                    (this.state.signupFailed) && <span
                                        className="text-danger"> Error: {this.state.errMessage}</span>
                                }
                            </CardBody>
                            <CardFooter>
                                <FormGroup>
                                    <Button type="submit" className="tim-white-buttons">
                                        Sign up
                                    </Button>
                                    <Button className="tim-white-buttons" onClick={() => {
                                        this.props.history.push(`/admin/login`);
                                    }}>
                                        Log in
                                    </Button>
                                </FormGroup>
                            </CardFooter>
                        </AvForm>
                    </Card>
                </div>
            </div>
        )}}

export default SignUp;