import Progress from "./views/progress/Progress.jsx";
import Tools from "./views/tools/Tools.jsx";
import LogIn from "./views/login/LogIn.jsx";
import React from "react";
import {Redirect, Route} from "react-router-dom";
import WorkoutData from "./views/workoutdata/WorkoutData";
import LifterCard from './views/liftercard/LifterCard';
import WorkoutTemplates from "./views/workouttemplates/WorkoutTemplates.jsx";
import WorkoutPlan from './views/schedules/WorkoutPlan';

var routes = [
    {
        path: "/progress",
        name: "Progress",
        icon: "tim-icons icon-chart-pie-36",
        component: Progress,
        layout: "/admin",
        invisible: false,
        isAuthenticated: true,
        isAdmin: false
    },
    {
        path: "/workout-data",
        name: "Workouts",
        icon: "tim-icons icon-single-02",
        component: WorkoutData,
        layout: "/admin",
        invisible: false,
        isAuthenticated: true,
        isAdmin: false
    },
    {
        path: "/lifter-card",
        name: "Lifter card",
        icon: "tim-icons icon-chart-pie-36",
        component: LifterCard,
        layout: "/admin",
        invisible: false,
        isAuthenticated: true,
        isAdmin: true
    },    
    {
        path: "/workout-plan",
        name: "Plan",
        icon: "tim-icons icon-paper",
        component: WorkoutPlan,
        layout: "/admin",
        invisible: false,
        isAuthenticated: true,
        isAdmin: true
    },
    {   
        path: "/templates",
        name: "Templates",
        icon: "tim-icons icon-paper",
        component: WorkoutTemplates,
        layout: "/admin",
        invisible: false,
        isAuthenticated: true,
        isAdmin: true
    },
    {
        path: "/tools",
        name: "Tools",
        icon: "tim-icons icon-settings",
        component: Tools,
        layout: "/admin",
        invisible: false,
        isAuthenticated: false,
        isAdmin: false
    },
    {
        path: "/login",
        name: "Login",
        icon: "tim-icons icon-single-02",
        component: LogIn,
        layout: "/admin",
        invisible: true,
        isAuthenticated: false,
        isAdmin: false
    },
    {
        path: "/sign-up",
        name: "Sign up",
        icon: "tim-icons icon-single-02",
        component: LogIn,
        layout: "/admin",
        invisible: true,
        isAuthenticated: false,
        isAdmin: false
    }
];

const renderMergedProps = (component, ...rest) => {
    const finalProps = Object.assign({}, ...rest);
    return (
        React.createElement(component, finalProps)
    );
};


export const AuthenticatedPropsRoute = ({component, loggedInUser, ...rest}) => {

    return (
        <Route {...rest} render={routeProps => {
            return loggedInUser ? renderMergedProps(component, routeProps, rest) :
                (<Redirect to={{
                    pathname: "/admin/login",
                    state: {from: routeProps.location}
                }}
                />)
        }}/>
    );
};

export const PropsRoute = ({component, loggedInUser, ...rest}) => {
    return (
        <Route {...rest} render={routeProps => {
            return renderMergedProps(component, routeProps, rest);
        }}/>
    );
};

export default routes;
