/*eslint-disable*/
import React from "react";

import { Container, Nav, NavItem, NavLink } from "reactstrap";

class Footer extends React.Component {
  render() {
    return (
      <footer className="footer">
        <Container fluid> 
          <div className="copyright">
            <Nav>
              <NavItem>
                <NavLink href="https://www.holmbergts.com">Holmberg TS © {new Date().getFullYear()}</NavLink>
              </NavItem>
            </Nav>
          </div>
        </Container>
      </footer>
    );
  }
}

export default Footer;
