import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Table
} from "reactstrap";

class Statistics extends React.Component {
  render() {
    return (
      <Card>
        <CardHeader>
          <Row>
            <Col className="text-left" sm="6">
              <CardTitle tag="h4">Statistics</CardTitle>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Table className="tablesorter">
            <thead className="text-primary">
              <tr>
                <th className="text-center">Avg reps per set</th>
                <th className="text-center">Max E1RM</th>
                <th className="text-center">Min E1RM</th>
                <th className="text-center">Diff E1RM</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center">
                  {this.props.statistics.averageReps}
                </td>
                <td className="text-center">{this.props.statistics.maxE1RM}</td>
                <td className="text-center">{this.props.statistics.minE1RM}</td>
                <td className="text-center">
                  {(this.props.statistics.maxE1RM -
                    this.props.statistics.minE1RM).toFixed(2)}
                </td>
              </tr>
            </tbody>
          </Table>
        </CardBody>
      </Card>
    );
  }
}

export default Statistics;
