import React from "react";

import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";

class Tools extends React.Component {

  handleOneRepMaxCalc() {
    this.props.history.push("/admin/tools/one-rep-max-calc");
  }

  handleIpfPointsCalc() {
    this.props.history.push("/admin/tools/ipf-points-calc");
  }

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <h4 className="title">Tools for better powerlifting</h4>
                </CardHeader>
                <CardBody className="all-icons">
                  <Row>
                    <Col
                      className="font-icon-list col-xs-6 col-xs-6"
                      lg="2"
                      md="3"
                      sm="4"
                    >
                      <div className="font-icon-detail hover-div" onClick={() => this.handleOneRepMaxCalc()}>
                        <h5>ESTIMATED ONE REP MAX CALCULATOR</h5>
                      </div>
                    </Col>
                    <Col
                      className="font-icon-list col-xs-6 col-xs-6"
                      lg="2"
                      md="3"
                      sm="4"
                    >
                      <div className="font-icon-detail hover-div" onClick={() => this.handleIpfPointsCalc()}>
                        <h5>IPF GL POINTS CALCULATOR</h5>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Tools;
