import moment from "moment";
import React from "react";
import { Col, Row } from "reactstrap";
import { getAggregatedUserData, getUserDayData, getUserStatistics } from "../../service/workoutDataService";
import { getUserBodyweights } from "../../service/weightService";
import AggregatedEntries from "./../progress/AggregatedEntries";
import PerformanceChart from "./../progress/PerformanceChart";
import Statistics from "./../progress/Statistics";
import DailyEntries from "./DailyEntries";
import LifterFilter from "./LifterFilter";
import WeightChart from "../weight/WeightChart";

class LifterCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bwWeights: [],
      bwLabels: [],
      weights: [],
      labels: [],
      aggregatedEntries: [],
      lifterEntries: [],
      statistics: {},
      selectedData: "",
      user: "",
      lift: "Squat",
      tempo: "",
      equipment: "",
      start: moment().subtract(2, "months").format("YYYY-MM-DD"),
      end: moment().format("YYYY-MM-DD"),
      showFetchMsg: false,
      fetchMsg: "",
      userDataMsg: "",
      showUserDataMsg: false,
      showAccessoriesMsg: false,
      accessoriesMsg: "",
    };
  }

  setLifterData = (user, lift, tempo, equipment, start, end) => {
    this.setAggregatedEntries(user, lift, tempo, equipment, start, end);
    this.setLifterEntries(user, start, end);
    this.setStatistics(user, lift, tempo, equipment, start, end);
    this.setBodyweights(user, start, end);
  };

  setLifterEntries = (user, start, end) => {
    getUserDayData(user, start, end)
      .then((resp) => {
        this.setState({
          showFetchMsg: false,
          lifterEntries: resp.data,
        });
        if (this.state.lifterEntries.length === 0) {
          this.setState({
            showFetchMsg: true,
            fetchMsg: "No entries found!",
          });
        }
      })
      .catch((error) => {
        this.setState({
          showFetchMsg: true,
          fetchMsg: "Could not fetch data!",
          lifterEntries: [],
        });
      });
  };

  setBodyweights = (user, start, end) => {
    getUserBodyweights(user, start, end)
    .then((resp) => {
      this.setState({
        bwWeights: resp.data
          .map((bw) => {
            return bw.weight;
        }),
        bwLabels: resp.data
          .map((bw) => {
            return bw.date.substring(0, 10);
          }),
        });
    })
    .catch((error) => {
      console.error(error);
    });
  }

  setStatistics = (user, lift, tempo, equipment, start, end) => {
    getUserStatistics(user, lift, tempo, equipment, start, end)
      .then((resp) => {
        this.setState({
          statistics: resp.data,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  setFilterState = (user, lift, tempo, equipment, start, end) => {
    this.setState(
      {
        user: user,
        lift: lift,
        tempo: tempo,
        equipment: equipment,
        start: start,
        end: end,
      },
      this.setLifterData(user, lift, tempo, equipment, start, end)
    );
  };

  getLiftWithTempoAndEquipment = (lift, tempo, equipment) => {
    const result =
      lift +
      (tempo !== '' ||
      equipment !== ''
        ? " ("
        : "") +
      (tempo !== '' ? tempo : "") +
      (tempo !== '' &&
      equipment !== ''
        ? ", "
        : "") +
      (equipment !== ''
        ? equipment
        : "") +
      (tempo !== '' ||
      equipment !== ''
        ? ")"
        : "");
    return result;
  }

  setAggregatedEntries(user, lift, tempo, equipment, start, end) {
    getAggregatedUserData(user, lift, tempo, equipment, start, end)
      .then((resp) => {
        this.setState({
          showFetchMsg: false,
          selectedData: this.getLiftWithTempoAndEquipment(lift, tempo, equipment),
          weights: resp.data
            .filter((entry) => entry.averageEstimatedMax !== 0)
            .map((entry) => {
              return entry.averageEstimatedMax;
            }),
          labels: resp.data
            .filter((entry) => entry.averageEstimatedMax !== 0)
            .map((entry) => {
              return entry.date.substring(0, 10);
            }),
          aggregatedEntries: resp.data,
        });
        if (this.state.aggregatedEntries.length === 0) {
          this.setState({
            showFetchMsg: true,
            fetchMsg: "No entries found!",
          });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          showFetchMsg: true,
          fetchMsg: "Could not fetch data!",
        });
      });
  }

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col xs="12">
              <LifterFilter
                setFilter={this.setFilterState}
                showFetchMsg={this.state.showFetchMsg}
                fetchMsg={this.state.fetchMsg}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <PerformanceChart
                weights={this.state.weights}
                labels={this.state.labels}
                selectedData={this.state.selectedData}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <WeightChart
                weights={this.state.bwWeights}
                labels={this.state.bwLabels}
              />
            </Col>
          </Row>
          <Row>
            <Col className="col-md-6">
              <AggregatedEntries
                userEntriesToShow={this.state.aggregatedEntries}
              />
            </Col>
            <Col className="col-md-6">
              <Statistics statistics={this.state.statistics} />
            </Col>
          </Row>
          <Row>
            <Col className="col-md-6">
              <DailyEntries dailyEntriesToShow={this.state.lifterEntries} />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default LifterCard;
