import React from "react";
import { CardBody, CardHeader, Table } from "reactstrap";

class LifterAccessoryEntries extends React.Component {
  render() {
    return (
      <>
        <CardHeader>
        </CardHeader>
        <CardBody>
          <Table className="tablesorter">
            <thead className="text-primary">
              <tr>
                <th>Lift</th>
                <th>Sets/reps/weight</th>
              </tr>
            </thead>
            <tbody>
              {this.props.userEntriesToShow.map((entry) => {
                return (
                  <tr>
                    <td>{entry.accessory}</td>
                    <td>{entry.accessoryData}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </CardBody>
      </>
    );
  }
}

export default LifterAccessoryEntries;
