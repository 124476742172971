import React from "react";
import { Line } from "react-chartjs-2";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import AddWeight from "./AddWeight";

class WeightChart extends React.Component {
  state = {
    showInfo: false,
    infoMsg: "",
    showError: false,
    errMsg: "",
  };

  getChartOptions = () => {
    return {
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
      tooltips: {
        backgroundColor: "#f5f5f5",
        titleFontColor: "#333",
        bodyFontColor: "#666",
        bodySpacing: 4,
        xPadding: 12,
        mode: "nearest",
        intersect: 0,
        position: "nearest",
      },
      responsive: true,
      scales: {
        yAxes: [
          {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: "rgba(29,140,248,0.0)",
              zeroLineColor: "transparent",
            },
            ticks: {
              suggestedMin: Math.min(...this.props.weights),
              suggestedMax: Math.max(...this.props.weights),
              stepSize: 1,
              maxTicksLimit: 6,
              padding: 20,
              fontColor: "#9a9a9a",
            },
          },
        ],
        xAxes: [
          {
            barPercentage: 1.6,
            gridLines: {
              drawBorder: false,
              color: "rgba(29,140,248,0.1)",
              zeroLineColor: "transparent",
            },
            ticks: {
              padding: 20,
              fontColor: "#9a9a9a",
            },
          },
        ],
      },
    };
  };

  render() {
    let chartData = {
      data: (canvas) => {
        let ctx = canvas.getContext("2d");

        let gradientStroke = ctx.createLinearGradient(0, 0, 0, 0);

        gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
        gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
        gradientStroke.addColorStop(0, "rgba(29,140,248,0)"); //blue colors

        return {
          labels: this.props.labels,
          datasets: [
            {
              label: "Bodyweight",
              fill: true,
              backgroundColor: gradientStroke,
              borderColor: "#1f8ef1",
              borderWidth: 1,
              borderDash: [],
              borderDashOffset: 0.0,
              pointBackgroundColor: "#1f8ef1",
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#1f8ef1",
              pointBorderWidth: 20,
              pointHoverRadius: 4,
              pointHoverBorderWidth: 15,
              pointRadius: 3,
              lineTension: 0,
              data: this.props.weights,
            },
          ],
        };
      },
    };
    return (
      <Card className="card-chart">
        <CardHeader>
          <Row>
            <Col>
              {this.state.showError && (
                <span className="text-danger"> Error: {this.state.errMsg}</span>
              )}
              {this.state.showInfo && (
                <span className="text-white">{this.state.infoMsg}</span>
              )}
            </Col>
          </Row>
          <Row>
            <Col className="text-left col-md-6">
              <CardTitle tag="h4">Bodyweight</CardTitle>
            </Col>
            <Col className="text-right col-md-6">
              <AddWeight updateWeights={this.props.updateWeights}/>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <div className="chart-area">
            <Line data={chartData["data"]} options={this.getChartOptions()} />
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default WeightChart;
