import React, {Component} from 'react';

class NotFound extends Component {

    render() {
        return (
            <div className="content">
                <div className="row">
                    HTTP 404! Page not found!
                </div>
            </div>
        )
    };
}

export default NotFound;