import moment from "moment";
import React from "react";
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row } from "reactstrap";

class FilterLifts extends React.Component {
  state = {
    start: moment().subtract(2, "months").format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
  };

  handleStartInput = (e) => {
    this.setState({
      start: e.target.value,
    });
  };

  handleEndInput = (e) => {
    this.setState({
      end: e.target.value,
    });
  };

  handleFilter = () => {
    this.props.setFilter(
      this.state.start,
      this.state.end
    );
  };

  render() {
    return (
      <Card>
        <CardHeader>
          <Row>
            <Col>
              {this.props.showFetchMsg && (
                <span className="text-danger">{this.props.fetchMsg}</span>
              )}
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <FormGroup className="col-md-6">
              <Label for="start">From</Label>
              <Input
                type="date"
                name="start"
                id="start"
                defaultValue={moment()
                  .subtract(2, "months")
                  .format("YYYY-MM-DD")}
                onChange={this.handleStartInput}
              />
            </FormGroup>
            <FormGroup className="col-md-6">
              <Label for="end">To</Label>
              <Input
                type="date"
                name="end"
                id="end"
                defaultValue={moment().format("YYYY-MM-DD")}
                onChange={this.handleEndInput}
              />
            </FormGroup>
          </Row>
          <Row>
            <Col>
            <FormGroup className="text-left">
              <br/>
              <Button className="tim-white-buttons" onClick={this.handleFilter}>
                Filter
              </Button>
            </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

export default FilterLifts;
