import axios from 'axios';

export const addMainLiftData = (date, lift, tempo, equipment, weight, reps, sets, rpe, comment, estimatedMax) => {
    return axios.post('/api/workout-data', {
        date,
        lift,
        tempo,
        equipment,
        weight,
        reps,
        sets,
        rpe,
        comment,
        estimatedMax
    }).then(response => {
        return response;
    })
};

export const addAccessoryData = (date, accessory, accessoryData) => {
    return axios.post('/api/workout-data/accessories', {
        date,
        accessory,
        accessoryData
    }).then(response => {
        return response;
    })
};

export const deleteData = (id) => {
    return axios.post('/api/workout-data/delete', {
        id
    }).then(response => {
        return response;
    })
};

export const deleteAccessoryData = (id) => {
    return axios.post('/api/workout-data/delete-accessory', {
        id
    }).then(response => {
        return response;
    })
};

export const getDayData = (start, end) => {
    return axios.get('/api/workout-data/day?start=' + start + '&end=' + end).then(response => {
        return response;
    })
}

export const getUserDayData = (user, start, end) => {
    return axios.get('/api/workout-data/day/user?start=' + start + '&end=' + end + '&user=' + user).then(response => {
        return response;
    })
}


export const getUserData = (user, lift, start, end) => {
    return axios.get('/api/workout-data/user?lift=' + lift + '&start=' + start + '&end=' + end + '&userId=' + user).then(response => {
        return response;
    })
};


export const getAccessories = () => {
    return axios.get('/api/workout-data/accessories').then(response => {
        return response;
    })
}

export const getUserAccessories = (user, start, end) => {
    return axios.get('/api/workout-data/accessories/user?userId=' + user + '&start=' + start + '&end=' + end).then(response => {
        return response;
    })
}

export const getAggregatedData = (lift, tempo, equipment, start, end) => {
    return axios.get('/api/workout-data/aggregated?lift=' + lift + '&tempo=' + tempo + '&equipment=' + equipment + '&start=' + start + '&end=' + end).then(response => {
        return response;
    })
}

export const getAggregatedUserData = (user, lift, tempo, equipment, start, end) => {
    return axios.get('/api/workout-data/aggregated/user?lift=' + lift + '&tempo=' + tempo + '&equipment=' + equipment + '&start=' + start + '&end=' + end + '&userId=' + user).then(response => {
        return response;
    })
}

export const getStatistics = (lift, tempo, equipment, start, end) => {
    return axios.get('/api/workout-data/statistics?lift=' + lift + '&tempo=' + tempo + '&equipment=' + equipment + '&start=' + start + '&end=' + end).then(response => {
        return response;
    })
}

export const getUserStatistics = (user, lift, tempo, equipment, start, end) => {
    return axios.get('/api/workout-data/statistics/user?lift=' + lift + '&tempo=' + tempo + '&equipment=' + equipment + '&start=' + start + '&end=' + end + '&userId=' + user).then(response => {
        return response;
    })
}