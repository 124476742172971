import React from "react";
class WorkoutPlan extends React.Component {
  render() {
    return (
      <>
        <div className="content">
          Siin hakkab olema kastidena Day 1, Day 2 jne, mis tulevad Workout templatest. Read on muudetavad. Kasutaja saab lisada oma trenni andmed. Vajutades salvesta, tekitatakse samasugused kirjed nagu ka Workout data alt lisades.
        </div>
      </>
    );
  }
}

export default WorkoutPlan;
