import axios from 'axios';

export const getUserWeight = (start, end) => {
    return axios.get('/api/weight-data?start=' + start + '&end=' + end).then(response => {
        return response;
    })
};

export const addWeight = (date, weight) => {
    return axios.post('/api/weight-data', {
        date,
        weight
    }).then(response => {
        return response;
    })
};

export const getUserBodyweights = (user, start, end) => {
    return axios.get('/api/weight-data/user?start=' + start + '&end=' + end + '&user=' + user).then(response => {
        return response;
    })
}
