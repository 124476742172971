import React from "react";
import {login} from "../../service/authService"
import {AvForm, AvField} from 'availity-reactstrap-validation';

import {
    FormGroup,
    Label,
    Button,
    Card,
    CardBody, CardHeader, CardFooter
} from "reactstrap";


class LogIn extends React.Component {
    state = {
        email: 'email@example.com',
        password: 'password',
        loginFailed: false,
        signUpSucceed: false,
        errMsg: ''
    };

    componentDidMount(){
        if (this.props.location.state) {
            this.setState({
                signUpSucceed: this.props.location.state.signUpSucceed
            })
        }
        const { state } = this.props.location;
        const stateCopy = { ...state };
        delete stateCopy.signUpSucceed;
        this.props.history.replace({ state: stateCopy });
    }

    handleEmailInput = (e) => {
        this.setState({
            email: e.target.value
        })
    };

    handlePasswordInput = (e) => {
        this.setState({
            password: e.target.value
        })
    };

    handleValidSubmit = () => {
        let errMsg;
        login(this.state.email, this.state.password)
            .then(user => {
                this.props.login(user);
                this.props.history.push("/admin/progress");
            })
            .catch(error => {
                if (error && error.status && error.status === 400) {
                    errMsg = "Incorrect username/password, please try again."
                } else {
                    errMsg = "Something went wrong, please try again later."
                }
                this.setState({
                    loginFailed: true,
                    signUpSucceed: false,
                    errMsg
                })
            })
    };

    render() {
        const {errMsg} = this.state;
        return (
            <div className="content">
                <div className="col-lg-4 col-md-4 col-sm-4 container justify-content-center">
                    <Card>
                        <CardHeader>
                            <h4 className="title">Log in</h4>
                            {
                                (this.state.signUpSucceed) && <span className="text-white"> Signed up successfully. Please log in. </span>
                                
                            }
                            {
                                (this.state.loginFailed) && <span className="text-danger"> {errMsg} </span>
                            }
                        </CardHeader>
                        <AvForm onValidSubmit={this.handleValidSubmit}>
                            <CardBody>
                                <FormGroup>
                                    <Label for="email">Email address</Label>
                                    <AvField
                                        type="email"
                                        name="email"
                                        id="email"
                                        placeholder="Enter email"
                                        onChange={this.handleEmailInput}
                                        required
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="examplePassword">Password</Label>
                                    <AvField
                                        type="password"
                                        name="password"
                                        id="examplePassword"
                                        placeholder="Password"
                                        autoComplete="off"
                                        onChange={this.handlePasswordInput}
                                        required
                                    />
                                </FormGroup>
                            </CardBody>
                            <CardFooter>
                                <FormGroup>
                                    <Button type="submit" className="tim-white-buttons">
                                        Log in
                                    </Button>
                                    <Button className="tim-white-buttons" onClick={() => {
                                        this.props.history.push(`/admin/sign-up`);
                                    }}>
                                        Sign up
                                    </Button>
                                </FormGroup>
                            </CardFooter>
                        </AvForm>
                    </Card>
                </div>
            </div>
        )
    }
};

export default LogIn;