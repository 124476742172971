import React from "react";
import { CardBody, CardHeader, Col, Row, Table } from "reactstrap";
import MainEntryRow from "./MainEntryRow";

class MainEntries extends React.Component {
  render() {
    return (
      <>
        <CardHeader>
          <Row>
            <Col>
              {this.props.showDeleteMsg && (
                <span className="text-white">{this.props.deleteMsg}</span>
              )}
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Table className="tablesorter">
            <thead className="text-primary">
              <tr>
                <th>Lift</th>
                <th>Weight</th>
                <th>Reps</th>
                <th>RPE</th>
                <th>E1RM</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {this.props.userEntriesToShow.map((entry) => {
                return (
                  <MainEntryRow
                    deleteEntry={this.props.deleteEntry}
                    entry={entry}
                  />
                );
              })}
            </tbody>
          </Table>
        </CardBody>
      </>
    );
  }
}

export default MainEntries;
