import React from "react";
import Moment from "react-moment";
import { Card, CardHeader, CardTitle, Col, Collapse, Row, Label } from "reactstrap";
import LifterAccessoryEntries from "./LifterAccessoryEntries";
import LifterEntries from "./LifterEntries";

class DailyEntry extends React.Component {
  state = {
    isOpen: false,
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    return (
      <>
        <Card>
          <CardHeader onClick={this.toggle} className='hover-div'>
            <Row>
              <Col>
                <CardTitle tag="h4">
                  <Label className='hover-div'>
                    <Moment format="YYYY-MM-DD">{this.props.date}</Moment>
                    {" "}
                    {this.props.mainLifts &&
                      this.props.mainLifts
                        .map(
                          (item) =>
                            item.lift +
                            (item.tempo !== "" || item.equipment !== ""
                              ? " ("
                              : "") +
                            (item.tempo !== "" ? item.tempo : "") +
                            (item.tempo !== "" && item.equipment !== ""
                              ? ", "
                              : "") +
                            (item.equipment !== "" ? item.equipment : "") +
                            (item.tempo !== "" || item.equipment !== ""
                              ? ")"
                              : "")
                        )
                        .filter((x, i, a) => a.indexOf(x) === i)
                        .toString()}
                    </Label>
                </CardTitle>
              </Col>
            </Row>
          </CardHeader>
          <Collapse isOpen={this.state.isOpen}>
            <blockquote className="blockquote">
              {this.props.mainLifts && (
                <LifterEntries
                  userEntriesToShow={this.props.mainLifts}
                />
              )}
              {this.props.accessories && (
                <LifterAccessoryEntries
                  userEntriesToShow={this.props.accessories}
                />
              )}
            </blockquote>
          </Collapse>
        </Card>
      </>
    );
  }
}

export default DailyEntry;
