import axios from 'axios';

axios.interceptors.response.use(
    res => {
        return res;
    },
    error => {
        return Promise.reject(error.response)
    }
);

export const signout = () => {
    return axios.post('/api/logout').then(user => {
        // delete axiosInstance.defaults.headers.common["Authorization"];
        return user.data
    })
};


export const init = () => {
    return axios.post('/api/init').then(user => {
        return user.data
    })
};

export const login = (email, password) => {
    return axios.post('/api/login', {
        email,
        password
    }).then(user => {
        // axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${user.data.token}`;
        return user.data
    })
};

export const signup = (name, email, password, betaKey) => {
    return axios.post('/api/signup', {
        name,
        email,
        password,
        betaKey
    }).then(user => {
        return user.data
    })
};