import React from "react";
import Moment from "react-moment";
import { Card, CardBody, CardHeader, CardTitle, Col, Row, Table } from "reactstrap";

class AggregatedEntries extends React.Component {

  render() {
    return (
      <Card>
        <CardHeader>
          <Row>
            <Col className="text-left" sm="6">
              <CardTitle tag="h4">Aggregated entries</CardTitle>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Table className="tablesorter">
            <thead className="text-primary">
              <tr>
                <th>Date</th>
                <th>Lift</th>
                <th className="text-center">Total sets</th>
                <th className="text-center">Total reps</th>
                <th className="text-center">Total kg</th>
                <th className="text-center">Average E1RM</th>
              </tr>
            </thead>
            <tbody>
              {this.props.userEntriesToShow.map(function(entry) {
                return (
                  <tr>
                    <td>
                      <Moment format="YYYY-MM-DD">{entry.date}</Moment>
                    </td>
                    <td>{entry.lift}</td>
                    <td className="text-center">{entry.totalSets}</td>
                    <td className="text-center">{entry.totalReps}</td>
                    <td className="text-center">{entry.totalKg}</td>
                    <td className="text-center font-weight-bold">
                      {entry.averageEstimatedMax}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    );
  }
}

export default AggregatedEntries;
