import moment from "moment";
import React from "react";
import { Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { deleteAccessoryData, deleteData, getDayData } from "../../service/workoutDataService";
import AddAccessoryLift from "./AddAccessoryLift";
import AddMainLift from "./AddMainLift";
import DailyEntries from "./DailyEntries";
import FilterLifts from "./FilterLifts";

class WorkoutData extends React.Component {
  state = {
    type: "Main",
    start: moment().subtract(2, "months").format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
    dailyEntriesToShow: [],
    showFetchMsg: false,
    fetchMsg: "",
    showDeleteMsg: false,
    deleteMsg: "",
    showAccessoryDeleteMsg: false,
    accessoryDeleteMsg: "",
    showInfo: false,
    infoMsg: "",
    showError: false,
    errMsg: "",
  };

  componentDidMount() {
    this.setDayData(this.state.start, this.state.end);
  }

  setDayDataFromState = () => {
    this.setDayData(this.state.start, this.state.end);
  }

  setDayData = (start, end) => {
    getDayData(start, end)
      .then((resp) => {
        this.setState({
          showFetchMsg: false,
          dailyEntriesToShow: resp.data,
        });
        if (this.state.dailyEntriesToShow.length === 0) {
          this.setState({
            showFetchMsg: true,
            fetchMsg: "No entries found!",
          });
        }
      })
      .catch((error) => {
        this.setState({
          showFetchMsg: true,
          fetchMsg: "Could not fetch data!",
          dailyEntriesToShow: [],
        });
      });
  };

  deleteEntry = (id) => {
    if (window.confirm("Are you sure?")) {
      deleteData(id)
        .then((resp) => {
          this.setState(
            {
              showDeleteMsg: true,
              deleteMsg: resp.data.message,
            },
            () => this.setDayDataFromState()
          );
        })
        .catch((error) => {
          this.setState({
            showDeleteMsg: true,
            deleteMsg: "Could not delete entry " + error,
          });
        });
    }
  };

  deleteAccessoryEntry = (id) => {
    if (window.confirm("Are you sure?")) {
      deleteAccessoryData(id)
        .then((resp) => {
          this.setState(
            {
              showAccessoryDeleteMsg: true,
              accessoryDeleteMsg: resp.data.message,
            },
            () => this.setDayDataFromState()
          );
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  setFilterState = (start, end) => {
    this.setState({
      start: start,
      end: end,
    });
    this.setDayData(start, end);
  };

  setMsg = (showInfo, infoMsg, showError, errMsg) => {
    this.setState({
      showInfo: showInfo,
      infoMsg: infoMsg,
      showError: showError,
      errMsg: errMsg,
    });
  };

  handleTypeChange = (e) => {
    this.setState({
      type: e.target.value,
    });
  };

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col sm="6">
              <Card>
                <CardHeader>
                  {this.state.showError && (
                    <span className="text-danger">
                      {" "}
                      Error: {this.state.errMsg}
                    </span>
                  )}
                  {this.state.showInfo && (
                    <span className="text-white">{this.state.infoMsg}</span>
                  )}
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col className="col-md-6">
                      <FormGroup>
                        <Label for="type">Type</Label>
                        <Input
                          type="select"
                          name="type"
                          id="type"
                          onChange={this.handleTypeChange}
                        >
                          <option className="text-black-50">Main</option>
                          <option className="text-black-50">Accessory</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <br></br>
                      {this.state.type === "Main" && (
                        <AddMainLift
                          setTableData={this.setDayDataFromState}
                          setMsg={this.setMsg}
                        />
                      )}
                      {this.state.type === "Accessory" && (
                        <AddAccessoryLift
                          setTableData={this.setDayDataFromState}
                          setMsg={this.setMsg}
                        />
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col sm="6">
              <FilterLifts
                setFilter={this.setFilterState}
                showFetchMsg={this.state.showFetchMsg}
                fetchMsg={this.state.fetchMsg}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <DailyEntries
                dailyEntriesToShow={this.state.dailyEntriesToShow}
                deleteEntry={this.deleteEntry}
                deleteAccessoryEntry={this.deleteAccessoryEntry}
                accessoryDeleteMsg={this.state.accessoryDeleteMsg}
                showAccessoryDeleteMsg={this.state.showAccessoryDeleteMsg}
                showDeleteMsg={this.state.showDeleteMsg}
                deleteMsg={this.state.showDeleteMsg}
              />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default WorkoutData;
