import React from "react";
import { Popover, PopoverBody } from "reactstrap";

class LifterEntryRow extends React.Component {
  state = {
    popoverOpen: false,
  };

  toggle = () => {
    this.setState({ popoverOpen: !this.state.popoverOpen });
  };

  render() {
    const lift =
      this.props.entry.lift +
      (this.props.entry.tempo !== "" || this.props.entry.equipment !== ""
        ? " ("
        : "") +
      (this.props.entry.tempo !== "" ? this.props.entry.tempo : "") +
      (this.props.entry.tempo !== "" && this.props.entry.equipment !== ""
        ? ", "
        : "") +
      (this.props.entry.equipment !== "" ? this.props.entry.equipment : "") +
      (this.props.entry.tempo !== "" || this.props.entry.equipment !== ""
        ? ")"
        : "");
    return (
      <>
        <tr onClick={this.toggle} id={"row" + this.props.entry._id} className={this.props.entry.comment !== '' ? 'hover-div' : ''}>
          <td>{lift} {this.props.entry.comment !== '' ? <i className='tim-icons icon-paper'/> : ''}</td>
          <td>{this.props.entry.weight}</td>
          <td>{this.props.entry.reps}</td>
          <td>{this.props.entry.rpe}</td>
          <td className="font-weight-bold">
            {this.props.entry.estimatedMax !== 0
              ? this.props.entry.estimatedMax
              : ""}
          </td>
        </tr>
        <tr>
          {this.props.entry.comment && (
            <Popover
              placement="bottom"
              isOpen={this.state.popoverOpen}
              target={"row" + this.props.entry._id}
            >
              <PopoverBody>{this.props.entry.comment}</PopoverBody>
            </Popover>
          )}
        </tr>
      </>
    );
  }
}

export default LifterEntryRow;
