import { AvField, AvForm } from "availity-reactstrap-validation";
import moment from "moment";
import React from "react";
import { Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { addAccessoryData } from "../../service/workoutDataService";

class AddAccessoryLift extends React.Component {
  state = {
    date: moment().format("YYYY-MM-DD"),
    accessory: "",
    accessoryData: "",
    showInfo: false,
    infoMsg: "",
    showError: false,
    errMsg: "",
    modal: false,
  };

  handleDateInput = (e) => {
    this.setState({
      date: e.target.value,
    });
  };

  handleAccessoryInput = (e) => {
    this.setState({
      accessory: e.target.value,
    });
  };

  handleAccessoryDataInput = (e) => {
    this.setState({
      accessoryData: e.target.value,
    });
  };

  handleValidAccessorySubmit = () => {
    addAccessoryData(
      this.state.date,
      this.state.accessory,
      this.state.accessoryData
    )
      .then(() => {
        this.setState(
          {
            showError: false,
            showInfo: true,
            infoMsg:
              "Successfully created entry for " +
              this.state.date +
              " " +
              this.state.accessory,
          },
          () => {
            this.setMsg();
            this.setState({
              date: moment().format("YYYY-MM-DD"),
              accessory: "",
              accessoryData: "",
            });
          }
        );
        //TODO: does not work
        this.props.setTableData();
      })
      .catch((error) => {
        console.log("Error: ", error);
        let err;
        if (error && error.data && error.data && error.data.message) {
          err = error.data.message;
        } else {
          err = "Something went wrong. Please try again.";
        }
        this.setState(
          {
            showInfo: false,
            showError: true,
            errMsg: err,
          },
          () => this.setMsg()
        );
      });
    this.toggle();
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  setMsg = () => {
    this.props.setMsg(
      this.state.showInfo,
      this.state.infoMsg,
      this.state.showError,
      this.state.errorMsg
    );
  };

  render() {
    return (
      <>
        <FormGroup className="text-left">
          <Button onClick={this.toggle}>
            Add
          </Button>
        </FormGroup>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader>
            <h5 className="title">Add accessory lift</h5>
          </ModalHeader>
          <AvForm onValidSubmit={this.handleValidAccessorySubmit}>
            <ModalBody>
              <FormGroup>
                <Label for="Date">Date</Label>
                <AvField
                  type="date"
                  name="date"
                  id="date"
                  className="text-black-50"
                  defaultValue={moment().format("YYYY-MM-DD")}
                  onChange={this.handleDateInput}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="accessory">Lift</Label>
                <AvField
                  type="text"
                  name="accessory"
                  id="accessory"
                  className="text-black-50"
                  required
                  onChange={this.handleAccessoryInput}
                />
              </FormGroup>
              <FormGroup>
                <Label for="accessoryData">Reps/sets/weight</Label>
                <AvField
                  type="textarea"
                  name="accessoryData"
                  id="accessoryData"
                  className="text-black-50"
                  required
                  onChange={this.handleAccessoryDataInput}
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button
                className="tim-white-buttons"
                type="button"
                onClick={this.toggle}
              >
                Close
              </Button>
              <Button className="tim-white-buttons" type="submit">
                Add
              </Button>
            </ModalFooter>
          </AvForm>
        </Modal>
      </>
    );
  }
}

export default AddAccessoryLift;
