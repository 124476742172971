import { AvField, AvForm } from "availity-reactstrap-validation";
import React from "react";
import { Button, Card, CardBody, CardFooter, CardHeader, Col, FormGroup, Label, Row } from "reactstrap";
import { getIpfGlPoints } from "../../service/ipfPointsService";


class IpfGlPointsCalculator extends React.Component {
  state = {
    total: "",
    bw: "",
    style: "Classic",
    gender: "Male",
    ipfPoints: "",
    error: false,
    errMsg: ""
  };

  handleTotalInput = e => {
    this.setState({
      total: e.target.value
    });
  };

  handleBwInput = e => {
    this.setState({
      bw: e.target.value
    });
  };

  handleStyleInput = e => {
    this.setState({
      style: e.target.value
    });
  };

  handleGenderInput = e => {
    this.setState({
      gender: e.target.value
    });
  };

  handleValidSubmit = () => {
    let errMsg;
    getIpfGlPoints(this.state.total, this.state.bw, this.state.style, this.state.gender)
      .then(result => {
        this.setState({
          error: false,
          errMsg: "",
          ipfPoints: result.data.result
        });
      })
      .catch(error => {
        if (error && error.status && error.status === 400) {
          errMsg = error.data.message;
        } else {
          errMsg = "Something went wrong, please try again later";
        }
        this.setState({
          error: true,
          ipfPoints: "",
          errMsg
        });
      });
  };

  render() {
    const { errMsg } = this.state;
    return (
      <>
        <div className="content">
          <div className="col-lg-4 col-md-4 col-sm-4 container justify-content-center">
            <Card>
              <CardHeader>
                <h5 className="title">Calculate IPF GL points</h5>
              </CardHeader>
              <AvForm onValidSubmit={this.handleValidSubmit}>
                <CardBody>
                  <Row>
                    <Col className="pr-md-1" md="">
                      <FormGroup>
                        <Label for="total">Total (kg)</Label>
                        <AvField
                          type="number"
                          name="total"
                          id="total"
                          required
                          min={0}
                          max={9999}
                          onChange={this.handleTotalInput}
                        />
                      </FormGroup>
                    </Col>
                    </Row>
                    <Row>
                    <Col className="pr-md-1" md="">
                      <FormGroup>
                        <Label for="bw">Bodyweight (kg)</Label>
                        <AvField
                          type="number"
                          name="bw"
                          id="bw"
                          required
                          min={0}
                          max={9999}
                          onChange={this.handleBwInput}
                        />
                      </FormGroup>
                    </Col>
                    </Row>
                    <Row>
                    <Col className="pr-md-1" md="">
                      <FormGroup>
                        <Label for="style">Style</Label>
                        <AvField
                          type="select"
                          name="style"
                          id="style"
                          onChange={this.handleStyleInput}
                        >
                          <option className="text-black-50">Classic</option>
                          <option className="text-black-50">Equipped</option>
                        </AvField>
                      </FormGroup>
                    </Col>
                    </Row>
                    <Row>
                    <Col className="pr-md-1" md="">
                      <FormGroup>
                        <Label for="style">Gender</Label>
                        <AvField
                          type="select"
                          name="gender"
                          id="gender"
                          onChange={this.handleGenderInput}
                        >
                          <option className="text-black-50">Male</option>
                          <option className="text-black-50">Female</option>
                        </AvField>
                      </FormGroup>
                    </Col>
                    </Row>
                  {this.state.error && (
                    <span className="text-danger"> {errMsg}</span>
                  )}
                </CardBody>
                <CardFooter>
                  <Button className="tim-white-buttons btn-block" type="submit">
                    Calculate
                  </Button>
                </CardFooter>
              </AvForm>
              <CardBody>
                {this.state.ipfPoints && (
                  <h4 className="title">
                    IPF GL points {" "}
                    {this.state.ipfPoints}
                  </h4>
                )}
              </CardBody>
            </Card>
          </div>
        </div>
      </>
    );
  }
}

export default IpfGlPointsCalculator;
