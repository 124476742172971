import React from "react";
import { Button, Card, CardBody, CardHeader, CardText, CardTitle, Row, Table, UncontrolledTooltip } from "reactstrap";
class WorkoutTemplates extends React.Component {
  render() {
    return (
      <>
        <div className="content">
        <Row>
            <Card>
              <CardHeader>
                <CardTitle>Add template to user</CardTitle>
              </CardHeader>
              <CardBody>
                <CardText>Siin saab siduda lifteri ja template</CardText>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle>Template-User relations</CardTitle>
              </CardHeader>
              <CardBody>
                <CardText>
                  <Table>
                    <thead>
                      <tr>
                        <th>Template name</th>
                        <th>User</th>
                        <th>Start date</th>
                        <th>End date</th>
                        <th/>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Template 1</td>
                        <td>Siim</td>
                        <td>2020-03-10</td>
                        <td>2020-03-17</td>
                        <td className="text-left">
                            <Button
                                className="btn-icon btn-simple"
                                color="default"
                                size="sm"
                            >
                                <i className="fa fa-times" />X
                            </Button>
                        </td>
                      </tr>
                      <tr>
                        <td>Template 1</td>
                        <td>Kristo</td>
                        <td>2020-03-15</td>
                        <td>2020-03-22</td>
                        <td className="text-left">
                            <Button
                                className="btn-icon btn-simple"
                                color="default"
                                size="sm"
                            >
                                <i className="fa fa-times" />X
                            </Button>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </CardText>
              </CardBody>
            </Card>
          </Row>
          <Row>
            <Card>
              <CardHeader>
                <CardTitle>Templates</CardTitle>
              </CardHeader>
              <CardBody>
                <CardText>
                  <Table>
                    <thead>
                      <tr>
                        <th>Template name</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Template 1</td>
                        <td>
                          D1: Bench press, OHP, Bambusega etteasted, D2: Squat,
                          Bambusega etteasted
                        </td>
                        <td className="td-actions text-right">
                          <Button
                            color="link"
                            id="tooltip831835125"
                            title=""
                            type="button"
                          >
                            <i className="tim-icons icon-pencil" />
                          </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip831835125"
                            placement="right"
                          >
                            Edit template
                          </UncontrolledTooltip>
                        </td>
                      </tr>
                      <tr>
                        <td>Template 2</td>
                        <td>D1: OHP, Bambusega etteasted, D2: Squat</td>
                        <td className="td-actions text-right">
                          <Button
                            color="link"
                            id="tooltip831835125"
                            title=""
                            type="button"
                          >
                            <i className="tim-icons icon-pencil" />
                          </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="tooltip831835125"
                            placement="right"
                          >
                            Edit template
                          </UncontrolledTooltip>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </CardText>
              </CardBody>
            </Card>
          </Row>
          <Row className="float-right">
            <Button>Add template</Button>
          </Row>
        </div>
      </>
    );
  }
}

export default WorkoutTemplates;
