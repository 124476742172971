import React from "react";
import { Button, CardBody, CardHeader, Col, Row, Table } from "reactstrap";

class AccessoryEntries extends React.Component {
  render() {
    return (
      <>
        <CardHeader>
          <Row>
            <Col>
              {this.props.showAccessoriesFetchMsg && (
                <span className="text-white">
                  {this.props.accessoriesFetchMsg}
                </span>
              )}
              {this.props.showAccessoryDeleteMsg && (
                <span className="text-white">
                  {this.props.accessoryDeleteMsg}
                </span>
              )}
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Table className="tablesorter">
            <thead className="text-primary">
              <tr>
                <th>Lift</th>
                <th>Reps/sets/weight</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {this.props.userEntriesToShow.map((entry) => {
                return (
                  <tr>
                    <td>{entry.accessory}</td>
                    <td>{entry.accessoryData}</td>
                    <td className="text-left">
                      <Button
                        className="btn-icon btn-simple"
                        color="default"
                        size="sm"
                        value={entry._id}
                        onClick={(e) => this.props.deleteEntry(e.target.value)}
                      >
                        <i className="fa fa-times" />X
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </CardBody>
      </>
    );
  }
}

export default AccessoryEntries;
