import { AvField, AvForm } from "availity-reactstrap-validation";
import moment from "moment";
import React from "react";
import { Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { getEstimatedMax } from "../../service/oneRepMaxService";
import { addMainLiftData } from "../../service/workoutDataService";

class AddMainLift extends React.Component {
  state = {
    date: moment().format("YYYY-MM-DD"),
    lift: "",
    tempo: "",
    equipment: "",
    weight: "",
    reps: "",
    sets: 1,
    rpe: "",
    estimatedMax: "",
    comment: "",
    modal: false,
    showInfo: false,
    showError: true,
    errMsg: "",
    infoMsg: "",
  };

  handleDateInput = (e) => {
    this.setState({
      date: e.target.value,
    });
  };

  handleLiftInput = (e) => {
    this.setState({
      lift: e.target.value,
    });
  };

  handleTempoInput = (e) => {
    this.setState({
      tempo: e.target.value,
    });
  };

  handleEquipmentInput = (e) => {
    this.setState({
      equipment: e.target.value,
    });
  };

  handleWeightInput = (e) => {
    this.setState({
      weight: e.target.value,
    });
  };

  handleRepsInput = (e) => {
    this.setState({
      reps: e.target.value,
    });
  };

  handleSetsInput = (e) => {
    this.setState({
      sets: e.target.value,
    });
  };

  handleRpeInput = (e) => {
    this.setState({
      rpe: e.target.value,
    });
  };

  handleCommentInput = (e) => {
    this.setState({
      comment: e.target.value,
    });
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  handleValidSubmit = () => {
    //TODO: If rpe is not inserted, do not call getEstimatedMax function. These methods are async,
    // so they should be tuned so that addData wont be called before estimated max value is retrieved
    var estimatedMaxInput = this.state.rpe !== "" ? this.state.rpe : 0;
    getEstimatedMax(this.state.weight, this.state.reps, estimatedMaxInput)
      .then((resp) => {
        var estimatedMax = resp.data.result;
        addMainLiftData(
          this.state.date,
          this.state.lift,
          this.state.tempo,
          this.state.equipment,
          this.state.weight,
          this.state.reps,
          this.state.sets,
          this.state.rpe,
          this.state.comment,
          estimatedMax
        )
          .then((resp) => {
            this.setState(
              {
                showError: false,
                showInfo: true,
                infoMsg:
                  "Successfully created entry for " +
                  this.state.date +
                  " " +
                  this.state.lift +
                  (this.state.tempo !== "" ? ", " + this.state.tempo : "") +
                  (this.state.equipment !== ""
                    ? ", " + this.state.equipment
                    : ""),
              },
              () => {
                this.setMsg();
              }
            );
            this.props.setTableData();
          })
          .catch((error) => {
            console.log("Error: ", error);
            let err;
            if (error && error.data && error.data && error.data.message) {
              err = error.data.message;
            } else {
              err = "Something went wrong. Please try again.";
            }
            this.setState(
              {
                showInfo: false,
                showError: true,
                errMsg: err,
              },
              () => this.setMsg()
            );
          });
      })
      .catch((error) => {
        let err;
        if (error && error.data && error.data && error.data.message) {
          err = error.data.message;
        } else {
          err = "Something went wrong. Could not calculate estimated 1RM.";
        }
        this.setState(
          {
            showInfo: false,
            showError: true,
            errMsg: err,
          },
          () => this.setMsg()
        );
      });
    this.toggle();
  };

  openModal = () => {
    this.setState({
      modal: !this.state.modal,
      date: moment().format("YYYY-MM-DD"),
      lift: "",
      tempo: "",
      equipment: "",
      weight: "",
      reps: "",
      sets: 1,
      rpe: "",
      estimatedMax: "",
      comment: "",
    })
  }

  setMsg = () => {
    this.props.setMsg(
      this.state.showInfo,
      this.state.infoMsg,
      this.state.showError,
      this.state.errorMsg
    );
  };

  render() {
    return (
      <>
        <FormGroup className="text-left">
          <Button onClick={this.openModal}>
            Add
          </Button>
        </FormGroup>
        <Modal isOpen={this.state.modal} toggle={this.toggle}>
          <ModalHeader>
            <h5 className="title">Add main lift</h5>
          </ModalHeader>
          <AvForm onValidSubmit={this.handleValidSubmit}>
            <ModalBody>
              <FormGroup>
                <Label for="Date">Date</Label>
                <AvField
                  type="date"
                  name="date"
                  id="date"
                  className="text-black-50"
                  defaultValue={moment().format("YYYY-MM-DD")}
                  onChange={this.handleDateInput}
                  required
                />
              </FormGroup>
              <FormGroup>
                <Label for="lift">Lift</Label>
                <AvField
                  type="select"
                  name="lift"
                  id="lift"
                  className="text-black-50"
                  onChange={this.handleLiftInput}
                  required
                >
                  <option className="text-black-50" />
                  <option className="text-black-50">Squat</option>
                  <option className="text-black-50">Bench press</option>
                  <option className="text-black-50">Deadlift</option>
                </AvField>
              </FormGroup>
              <FormGroup>
                <Label for="tempo">Tempo</Label>
                <AvField
                  type="select"
                  name="tempo"
                  id="tempo"
                  className="text-black-50"
                  onChange={this.handleTempoInput}
                >
                  <option className="text-black-50" />
                  <option className="text-black-50">Pause</option>
                  <option className="text-black-50">320</option>
                  <option className="text-black-50">303</option>
                  <option className="text-black-50">400</option>
                  <option className="text-black-50">600</option>
                  <option className="text-black-50">003</option>
                  <option className="text-black-50">Touch n go</option>
                  <option className="text-black-50">3ct pause</option>
                </AvField>
              </FormGroup>
              <FormGroup>
                <Label for="equipment">Equipment</Label>
                <AvField
                  type="select"
                  name="equipment"
                  id="equipment"
                  className="text-black-50"
                  onChange={this.handleEquipmentInput}
                >
                  <option className="text-black-50" />
                  <option className="text-black-50">High bar</option>
                  <option className="text-black-50">Safety bar</option>
                  <option className="text-black-50">Close grip</option>
                  <option className="text-black-50">Floor</option>
                  <option className="text-black-50">Low block</option>
                  <option className="text-black-50">High block</option>
                  <option className="text-black-50">Low pin</option>
                  <option className="text-black-50">High pin</option>
                  <option className="text-black-50">Conventional</option>
                  <option className="text-black-50">Sumo</option>
                  <option className="text-black-50">Close stance</option>
                  <option className="text-black-50">Wide stance</option>
                  <option className="text-black-50">Chains</option>
                  <option className="text-black-50">Bands</option>
                  <option className="text-black-50">Box</option>
                  <option className="text-black-50">Front</option>
                  <option className="text-black-50">Snatch grip</option>
                  <option className="text-black-50">Beltless</option>
                  <option className="text-black-50">Larsen</option>
                </AvField>
              </FormGroup>
              <FormGroup>
                <Label for="weight">Weight</Label>
                <AvField
                  type="number"
                  name="weight"
                  id="weight"
                  className="text-black-50"
                  required
                  onChange={this.handleWeightInput}
                  min={0}
                  max={999}
                />
              </FormGroup>
              <FormGroup>
                <Label for="reps">Reps</Label>
                <AvField
                  type="select"
                  name="reps"
                  id="reps"
                  className="text-black-50"
                  onChange={this.handleRepsInput}
                  required
                >
                  <option className="text-black-50" />
                  <option className="text-black-50">1</option>
                  <option className="text-black-50">2</option>
                  <option className="text-black-50">3</option>
                  <option className="text-black-50">4</option>
                  <option className="text-black-50">5</option>
                  <option className="text-black-50">6</option>
                  <option className="text-black-50">7</option>
                  <option className="text-black-50">8</option>
                  <option className="text-black-50">9</option>
                  <option className="text-black-50">10</option>
                </AvField>
              </FormGroup>
              <FormGroup>
                <Label for="sets">Sets</Label>
                <AvField
                  type="select"
                  name="sets"
                  id="sets"
                  className="text-black-50"
                  onChange={this.handleSetsInput}
                >
                  <option className="text-black-50">1</option>
                  <option className="text-black-50">2</option>
                  <option className="text-black-50">3</option>
                  <option className="text-black-50">4</option>
                  <option className="text-black-50">5</option>
                  <option className="text-black-50">6</option>
                  <option className="text-black-50">7</option>
                  <option className="text-black-50">8</option>
                  <option className="text-black-50">9</option>
                  <option className="text-black-50">10</option>
                </AvField>
              </FormGroup>
              <FormGroup>
                <Label for="rpe">RPE</Label>
                <AvField
                  type="select"
                  name="rpe"
                  id="rpe"
                  className="text-black-50"
                  onChange={this.handleRpeInput}
                >
                  <option className="text-black-50" />
                  <option className="text-black-50">5</option>
                  <option className="text-black-50">5.5</option>
                  <option className="text-black-50">6</option>
                  <option className="text-black-50">6.5</option>
                  <option className="text-black-50">7</option>
                  <option className="text-black-50">7.5</option>
                  <option className="text-black-50">8</option>
                  <option className="text-black-50">8.5</option>
                  <option className="text-black-50">9</option>
                  <option className="text-black-50">9.5</option>
                  <option className="text-black-50">10</option>
                </AvField>
              </FormGroup>
              <FormGroup>
                <Label for="comment">Comment</Label>
                <AvField
                  type="textarea"
                  className="text-black-50"
                  name="comment"
                  id="comment"
                  onChange={this.handleCommentInput}
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button
                className="tim-white-buttons"
                type="button"
                onClick={this.toggle}
              >
                Close
              </Button>
              <Button className="tim-white-buttons" type="submit">
                Add
              </Button>
            </ModalFooter>
          </AvForm>
        </Modal>
      </>
    );
  }
}

export default AddMainLift;
