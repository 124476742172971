import moment from "moment";
import React from "react";
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { getAllUsers } from "./../../service/userService";

class LifterFilter extends React.Component {
  state = {
    users: [],
    user: "",
    filterLift: "Squat",
    filterTempo: "",
    filterEquipment: "",
    start: moment().subtract(2, "months").format("YYYY-MM-DD"),
    end: moment().format("YYYY-MM-DD"),
  };

  handleFilterLiftChange = (e) => {
    this.setState({
      filterLift: e.target.value,
    });
  };

  handleFilterTempoChange = (e) => {
    this.setState({
      filterTempo: e.target.value,
    });
  };

  handleFilterEquipmentChange = (e) => {
    this.setState({
      filterEquipment: e.target.value,
    });
  };

  handleUserChange = (e) => {
    this.setState({
      user: e.target.value,
    });
  };

  handleStartInput = (e) => {
    this.setState({
      start: e.target.value,
    });
  };

  handleEndInput = (e) => {
    this.setState({
      end: e.target.value,
    });
  };

  handleFilter = () => {
    this.props.setFilter(
      this.state.user,
      this.state.filterLift,
      this.state.filterTempo,
      this.state.filterEquipment,
      this.state.start,
      this.state.end
    );
  };

  componentDidMount() {
    getAllUsers()
      .then((resp) => {
        this.setState({
          users: resp.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <Card>
        <CardHeader>
          <Row>
            <Col>
              {this.props.showFetchMsg && (
                <span className="text-danger">{this.props.fetchMsg}</span>
              )}
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Row>
            <FormGroup className="col-md-2">
              <Label for="user">User</Label>
              <Input
                type="select"
                name="user"
                id="user"
                onChange={this.handleUserChange}
              >
                <option className="text-black-50"/>
                {this.state.users.map((entry) => {
                  return (
                    <option className="text-black-50" value={entry._id}>
                      {entry.name}
                    </option>
                  );
                })}
              </Input>
            </FormGroup>
            <FormGroup className="col-md-2">
              <Label for="filterLift">Lift</Label>
              <Input
                type="select"
                name="filterLift"
                id="filterLift"
                onChange={this.handleFilterLiftChange}
              >
                <option className="text-black-50">Squat</option>
                <option className="text-black-50">Bench press</option>
                <option className="text-black-50">Deadlift</option>
              </Input>
            </FormGroup>
            <FormGroup className="col-md-2">
              <Label for="tempo">Tempo</Label>
              <Input
                type="select"
                name="tempo"
                id="tempo"
                onChange={this.handleFilterTempoChange}
              >
                <option className="text-black-50" />
                <option className="text-black-50">Pause</option>
                <option className="text-black-50">320</option>
                <option className="text-black-50">303</option>
                <option className="text-black-50">400</option>
                <option className="text-black-50">600</option>
                <option className="text-black-50">003</option>
                <option className="text-black-50">Touch n go</option>
                <option className="text-black-50">3ct pause</option>
              </Input>
            </FormGroup>
            <FormGroup className="col-md-2">
              <Label for="equipment">Equipment</Label>
              <Input
                type="select"
                name="equipment"
                id="equipment"
                onChange={this.handleFilterEquipmentChange}
              >
                <option className="text-black-50" />
                <option className="text-black-50">High bar</option>
                <option className="text-black-50">Safety bar</option>
                <option className="text-black-50">Close grip</option>
                <option className="text-black-50">Floor</option>
                <option className="text-black-50">Low block</option>
                <option className="text-black-50">High block</option>
                <option className="text-black-50">Low pin</option>
                <option className="text-black-50">High pin</option>
                <option className="text-black-50">Conventional</option>
                <option className="text-black-50">Sumo</option>
                <option className="text-black-50">Close stance</option>
                <option className="text-black-50">Wide stance</option>
                <option className="text-black-50">Chains</option>
                <option className="text-black-50">Bands</option>
                <option className="text-black-50">Box</option>
                <option className="text-black-50">Front</option>
                <option className="text-black-50">Snatch grip</option>
                <option className="text-black-50">Beltless</option>
                <option className="text-black-50">Larsen</option>
              </Input>
            </FormGroup>
            <FormGroup className="col-md-2">
              <Label for="start">From</Label>
              <Input
                type="date"
                name="start"
                id="start"
                defaultValue={moment()
                  .subtract(2, "months")
                  .format("YYYY-MM-DD")}
                onChange={this.handleStartInput}
              />
            </FormGroup>
            <FormGroup className="col-md-2">
              <Label for="end">To</Label>
              <Input
                type="date"
                name="end"
                id="end"
                defaultValue={moment().format("YYYY-MM-DD")}
                onChange={this.handleEndInput}
              />
            </FormGroup>
            <FormGroup className="col-md-2">
              <br />
              <Button className="tim-white-buttons" onClick={this.handleFilter}>
                Filter
              </Button>
            </FormGroup>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

export default LifterFilter;
