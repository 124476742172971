import moment from "moment";
import React from "react";
import {Col, Row} from "reactstrap";
import {getAggregatedData, getStatistics} from "../../service/workoutDataService";
import {getUserWeight} from "../../service/weightService";
import AggregatedEntries from "./AggregatedEntries";
import FilterLifts from "./FilterLifts";
import PerformanceChart from "./PerformanceChart";
import Statistics from "./Statistics";
import WeightChart from "../weight/WeightChart";

class Progress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      weights: [],
      bwWeights: [],
      labels: [],
      bwLabels: [],
      userEntriesToShow: [],
      statistics: {},
      selectedData: "Squat",
      lift: "Squat",
      tempo: "",
      equipment: "",
      start: moment().subtract(2, "months").format("YYYY-MM-DD"),
      end: moment().format("YYYY-MM-DD"),
      showFetchMsg: false,
      fetchMsg: "",
    };
  }

  getLiftWithTempoAndEquipment = (lift, tempo, equipment) => {
    return lift +
      (tempo !== '' ||
      equipment !== ''
        ? " ("
        : "") +
      (tempo !== '' ? tempo : "") +
      (tempo !== '' &&
      equipment !== ''
        ? ", "
        : "") +
      (equipment !== ''
        ? equipment
        : "") +
      (tempo !== '' ||
      equipment !== ''
        ? ")"
        : "");
  }

  setBwChartData = (start, end) => {
    getUserWeight(start, end).then((resp) => {
      this.setState({
        bwWeights: resp.data
          .map((entry) => {
            return entry.weight;
          }),
        bwLabels: resp.data
          .map((entry) => {
            return entry.date.substring(0, 10);
          })
      })
    }).catch(error => {
      console.error(error);
    })
  }

  setChartData = (lift, tempo, equipment, start, end) => {
    getAggregatedData(lift, tempo, equipment, start, end)
      .then((resp) => {
        this.setState({
          showFetchMsg: false,
          selectedData: this.getLiftWithTempoAndEquipment(lift, tempo, equipment),
          weights: resp.data
            .filter((entry) => entry.averageEstimatedMax !== 0)
            .map((entry) => {
              return entry.averageEstimatedMax;
            }),
          labels: resp.data
            .filter((entry) => entry.averageEstimatedMax !== 0)
            .map((entry) => {
              return entry.date.substring(0, 10);
            }),
          userEntriesToShow: resp.data,
        });
        if (this.state.userEntriesToShow.length === 0) {
          this.setState({
            showFetchMsg: true,
            fetchMsg: "No entries found!",
          });
        }
        this.setStatistics(lift, tempo, equipment, start, end);
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          showFetchMsg: true,
          fetchMsg: "Could not fetch data!",
        });
      });
  };

  setStatistics = (lift, tempo, equipment, start, end) => {
    getStatistics(lift, tempo, equipment, start, end)
      .then((resp) => {
        this.setState({
          statistics: resp.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  setFilterState = (lift, tempo, equipment, start, end) => {
    this.setState({
      filterLift: lift,
      tempo: tempo,
      equipment: equipment,
      start: start,
      end: end,
    });
    this.setChartData(lift, tempo, equipment, start, end);
    this.setBwChartData(start, end);
  };

  updateWeights = () => {
    this.setBwChartData(this.state.start, this.state.end);
  }

  componentDidMount() {
    this.setChartData(
      this.state.lift,
      this.state.tempo,
      this.state.equipment,
      this.state.start,
      this.state.end
    );
    this.setBwChartData(this.state.start, this.state.end);
  }

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col xs="12">
              <FilterLifts
                setFilter={this.setFilterState}
                showFetchMsg={this.state.showFetchMsg}
                fetchMsg={this.state.fetchMsg}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <PerformanceChart
                weights={this.state.weights}
                labels={this.state.labels}
                selectedData={this.state.selectedData}
              />
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <WeightChart
                weights={this.state.bwWeights}
                labels={this.state.bwLabels}
                updateWeights={this.updateWeights}
              />
            </Col>
          </Row>
          <Row>
            <Col className="col-md-6">
              <AggregatedEntries
                userEntriesToShow={this.state.userEntriesToShow}
              />
            </Col>
            <Col className="col-md-6">
              <Statistics statistics={this.state.statistics} />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Progress;
