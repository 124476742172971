import React from "react";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import DailyEntry from "./DailyEntry";

class DailyEntries extends React.Component {

  render() {
    return (
      <>
        <Card>
          <CardHeader>
            <Row>
              <Col>
                <CardTitle tag="h4">Workouts</CardTitle>
              </Col>
            </Row>
            <Row>
              <Col>
                {this.props.showMainDeleteMsg && (
                  <span className="text-white">
                    {this.props.mainDeleteMsg}
                  </span>
                )}
                {this.props.showAccessoryDeleteMsg && (
                  <span className="text-white">
                    {this.props.accessoryDeleteMsg}
                  </span>
                )}
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            {this.props.dailyEntriesToShow.map((entry) => {
              return (
                <DailyEntry
                  date={entry.date}
                  mainLifts={entry.mainLifts}
                  accessories={entry.accessories}
                  deleteEntry={this.props.deleteEntry}
                  deleteAccessoryEntry={this.props.deleteAccessoryEntry}
                />
              );
            })}
          </CardBody>
        </Card>
      </>
    );
  }
}

export default DailyEntries;
