import React from "react";
import { CardBody, CardHeader, Col, Row, Table } from "reactstrap";
import LifterEntryRow from "./LifterEntryRow";

class LifterEntries extends React.Component {
  render() {
    return (
      <>
        <CardHeader>
          <Row>
            <Col>
              {this.props.showUserDataMsg && (
                <span className="text-danger">{this.props.userDataMsg}</span>
              )}
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Table className="tablesorter">
            <thead className="text-primary">
              <tr>
                <th>Lift</th>
                <th>Weight</th>
                <th>Reps</th>
                <th>RPE</th>
                <th>E1RM</th>
                <th/>
              </tr>
            </thead>
            <tbody>
              {this.props.userEntriesToShow.map(entry => {
                return (
                  <LifterEntryRow
                    entry={entry}
                  />
                );
              })}
            </tbody>
          </Table>
        </CardBody>
      </>
    );
  }
}

export default LifterEntries;
