import React from "react";

class Loader extends React.Component {
    render() {
        return (
            <div className="content">
                <div className="row">
                    Loading...
                </div>
            </div>
        )
    };
}

export default Loader;