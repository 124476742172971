import React from "react";
import {Switch} from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";
import {init, signout} from '../service/authService'

// core components
import AdminNavbar from "./../components/Navbars/AdminNavbar.jsx";
import Footer from "./../components/Footer/Footer.jsx";
import Sidebar from "./../components/Sidebar/Sidebar.jsx";
import Loader from "./../components/Loader/Loader.jsx";
import routes from "./../routes.js";
import {AuthenticatedPropsRoute, PropsRoute} from "./../routes";
import LogIn from "../views/login/LogIn";
import Tools from "../views/tools/Tools";
import Progress from "../views/progress/Progress";
import SignUp from "../views/signup/SignUp";
import NotFound from "../views/error/NotFound";
import OneRepMaxCalc from "../views/tools/OneRepMaxCalc";
import WorkoutData from "../views/workoutdata/WorkoutData";
import LifterCard from './../views/liftercard/LifterCard';
import IpfGlPointsCalculator from './../views/tools/IpfGlPointsCalculator';
import WorkoutTemplates from "../views/workouttemplates/WorkoutTemplates";
import WorkoutPlan from './../views/schedules/WorkoutPlan';

var ps;

class Admin extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            init: true,
            loggedInUser: '',
            token: '',
            backgroundColor: "blue",
            isAdministrator: false,
            sidebarOpened:
                document.documentElement.className.indexOf("nav-open") !== -1
        };
    }

    handleLogin = (user) => {
        this.setState({
            loggedInUser: user,
            isAdministrator: user.isAdministrator,
            init: false
        });
    };

    handleLogout = () => {
        signout()
            .then(() => {
                this.setState({
                    loggedInUser: '',
                    isAdministrator: false,
                    token: ''
                })
            })
            .catch(error => {
                this.setState({ errMessage: error })
            });
        this.props.history.push("/admin/login");
    };

    componentDidMount() {
        init()
            .then(user => {
                this.handleLogin(user)
            })
            .catch(e => {
                this.setState({
                    init: false
                })
            });
        if (navigator.platform.indexOf("Win") > -1) {
            document.documentElement.className += " perfect-scrollbar-on";
            document.documentElement.classList.remove("perfect-scrollbar-off");
            ps = new PerfectScrollbar(this.refs.mainPanel, {suppressScrollX: true});
            let tables = document.querySelectorAll(".table-responsive");
            for (let i = 0; i < tables.length; i++) {
                ps = new PerfectScrollbar(tables[i]);
            }
        }
    }

    componentWillUnmount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
            document.documentElement.className += " perfect-scrollbar-off";
            document.documentElement.classList.remove("perfect-scrollbar-on");
        }
    }

    componentDidUpdate(e) {
        if (e.history.action === "PUSH") {
            if (navigator.platform.indexOf("Win") > -1) {
                let tables = document.querySelectorAll(".table-responsive");
                for (let i = 0; i < tables.length; i++) {
                    ps = new PerfectScrollbar(tables[i]);
                }
            }
            document.documentElement.scrollTop = 0;
            document.scrollingElement.scrollTop = 0;
            this.refs.mainPanel.scrollTop = 0;
        }
    }

    // this function opens and closes the sidebar on small devices
    toggleSidebar = () => {
        document.documentElement.classList.toggle("nav-open");
        this.setState({sidebarOpened: !this.state.sidebarOpened});
    };

    getBrandText = path => {
        for (let i = 0; i < routes.length; i++) {
            if (
                this.props.location.pathname.indexOf(
                    routes[i].layout + routes[i].path
                ) !== -1
            ) {
                return routes[i].name;
            }
        }
        return "Brand";
    };

    render() {
        return (
            <>
                <div className="wrapper">
                    <Sidebar
                        {...this.props}
                        routes={routes}
                        bgColor={this.state.backgroundColor}
                        toggleSidebar={this.toggleSidebar}
                        isAdministrator={this.state.isAdministrator}
                    />
                    <div
                        className="main-panel"
                        ref="mainPanel"
                        data={this.state.backgroundColor}
                    >
                        <AdminNavbar
                            {...this.props}
                            brandText={this.getBrandText(this.props.location.pathname)}
                            toggleSidebar={this.toggleSidebar}
                            sidebarOpened={this.state.sidebarOpened}
                            loggedInUser={this.state.loggedInUser}
                            logout={this.handleLogout}
                        />
                        {(this.state.init) ?
                            <Loader/> :
                            <Switch>
                                <PropsRoute path="/admin/sign-up" component={SignUp} />
                                <PropsRoute path="/admin/tools/one-rep-max-calc" component={OneRepMaxCalc} />
                                <PropsRoute path="/admin/tools/ipf-points-calc" component={IpfGlPointsCalculator} />
                                <PropsRoute path="/admin/tools" component={Tools} />
                                <AuthenticatedPropsRoute path="/admin/progress" component={Progress}
                                                         loggedInUser={this.state.loggedInUser}/>
                                <AuthenticatedPropsRoute path="/admin/workout-data" component={WorkoutData}
                                                         loggedInUser={this.state.loggedInUser}/>
                                <AuthenticatedPropsRoute path="/admin/lifter-card" component={LifterCard}
                                                         loggedInUser={(this.state.loggedInUser && this.state.isAdministrator)}/>
                                <AuthenticatedPropsRoute path="/admin/workout-plan" component={WorkoutPlan}
                                                         loggedInUser={(this.state.loggedInUser && this.state.isAdministrator)}/>
                                <AuthenticatedPropsRoute path="/admin/templates" component={WorkoutTemplates}
                                                         loggedInUser={(this.state.loggedInUser && this.state.isAdministrator)}/>
                                <PropsRoute path="/admin/login" component={LogIn} login={this.handleLogin}/>
                                <PropsRoute component={NotFound}/>
                            </Switch>
                        }
                        <Footer fluid/>
                    </div>
                </div>
            </>
        );
    }
}

export default Admin;
